import dayjs from 'dayjs';

/*************************************************************
 * where句に用いる、本日の日付取得
 *
 * @param 	なし
 * @return 	date (Timestamp)
 *************************************************************/
export const getToday = () => {
  return new Date();
};
